












import { Vue, Component } from "vue-property-decorator";
import ApplicationTopToolbar from "@/components/application/ApplicationTopToolbar.vue";
import ApplicationNavigationDrawer from "@/components/application/ApplicationNavigationDrawer.vue";
import ApplicationErrorSnackbar from "@/components/application/ApplicationErrorSnackbar.vue";
import ApplicationWarningSnackbar from "@/components/application/ApplicationWarningSnackbar.vue";
import { initIugu } from "@/domains/iugu/iugu";

@Component({
  components: {
    ApplicationTopToolbar,
    ApplicationNavigationDrawer,
    ApplicationErrorSnackbar,
    ApplicationWarningSnackbar,
  },
})
export default class App extends Vue {
  state = this.$store.state.appstate;

  mounted() {
    this.$store.dispatch("appstate/setTopToolbarVisibility", true);
    initIugu("DDDB0C89A582A1E743C643088E40BA9D", "Não");
  }
}
