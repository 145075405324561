import { RouteConfig } from "vue-router";

export default class PlanosRoute {
  static route(): RouteConfig {
    return {
      //para conseguir acessar tela de escolha de planos:
      //   path: "/planos",
      //   name: "Planos",
      //   component: () => import("@/components/contratacao/conta*"),
      path: "/contratacao",
      name: "Planos",
      component: () =>
        import("@/components/contratacao/conta/CadastroConta.vue"),
    };
  }
}
