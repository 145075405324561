/* eslint-disable no-undef */


const initIugu = (accountId, testMode) => {
    const IuguAccountId = accountId
    const IuguTestMode = testMode === 'Sim' ? true : false

    Iugu.setAccountID(IuguAccountId)
    Iugu.setTestMode(IuguTestMode)
    Iugu.setup()
}

const createCreditCard = (creditCard) => {

    const { cardNumber, dueMonth, dueYear, name, lastName, verificationValue } = creditCard

    if (dueYear > 2099) throw '[BusinessError] Informe um ano válido de expiração'

    if (!Iugu.utils.validateCreditCardNumber(cardNumber)) throw `[BusinessError] Número do cartão de crédito inválido: ${cardNumber}`

    if (!Iugu.utils.validateExpiration(dueMonth, dueYear)) throw `[BusinessError] Data de expiração do cartão de crédito inválida: ${dueMonth}/${dueYear}`

    const brand = Iugu.utils.getBrandByCreditCardNumber(cardNumber)

    if (brand) {
        if (!Iugu.utils.validateCVV(verificationValue, brand)) throw `[BusinessError] Código de verificação inválido: ${verificationValue}`
    }

    return Iugu.CreditCard(cardNumber, dueMonth, dueYear, name, lastName, verificationValue)

}

const createToken = (creditCard) => new Promise((resolve, reject) => {

    if (!creditCard) reject('Cartão de crédito inválido')

    Iugu.createPaymentToken(creditCard, (response => {

        if (response.errors) {
            reject(response.errors)
            throw response.errors
        }
        if (!response.errors) resolve(response.id)

    }))

})

const getFlagCard = (cardNumber) => {
    return Iugu.utils.getBrandByCreditCardNumber(cardNumber)
}

module.exports = {
    createCreditCard,
    createToken,
    getFlagCard,
    initIugu
}