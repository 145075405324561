import { RouteConfig } from "vue-router"
import ContratacaoRoute from "./Routes/ContratacaoRoute"
import NotFoundRoute from "./Routes/NotFoundRoute"
import PlanosRoute from "./Routes/PlanosRoute"
import UrlLauncherRoute from "./Routes/UrlLauncherRoute"

export default class RouteCollector {
    static routes(): Array<RouteConfig> {
        return [
            UrlLauncherRoute.route(),
            ContratacaoRoute.route(),
            PlanosRoute.route(),
            NotFoundRoute.route()
        ]
    }
}