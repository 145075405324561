import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO"
import store from "@/store"
import { RouteConfig } from "vue-router"

export default class ContratacaoRoute {
    static route(): RouteConfig {
        return {
            path: "/contratacao",
            name: "Contratacao",
            component: () => import("@/views/contratacao/index.vue"),
            beforeEnter: (to, from, next) => {
                const contratacaoCompleta = store.getters['contratacaostate/contratacao'] as ContratacaoDTO
                contratacaoCompleta.plano_selecionado === '' ? next({ name: 'Planos' }) : next()
            }
        }
    }
}