import { Module } from "vuex"
import { RootState } from "@/store"

export interface AppState {
    isGloballyLoading: boolean
    isShowingLeftToolbar: boolean
    isShowingTopToolbar: boolean
    isShowingModal: boolean
    snackbarError: boolean
    snackbarWarning: boolean
    snackbarErrorTimeout: number
    snackbarErrorMessage: string
    snackbarWarningMessage: string
    jwt?: string
}

export const ApplicationState: Module<AppState, RootState> = {

    namespaced: true,

    state: {
        isShowingLeftToolbar: false,
        isShowingTopToolbar: true,
        isGloballyLoading: true,
        isShowingModal: false,
        snackbarError: false,
        snackbarWarning: false,
        snackbarErrorTimeout: 30000,
        snackbarErrorMessage: '',
        snackbarWarningMessage: ''
    },
    getters: {
        isShowingTopToolbar(state) {
            return state.isShowingTopToolbar
        },
        isShowingLeftToolbar(state) {
            return state.isShowingLeftToolbar
        },
        isShowingModal(state) {
            return state.isShowingModal
        }
    },
    mutations: {
        setGlobalLoadingState(state, shouldLoad: boolean) {
            state.isGloballyLoading = shouldLoad
        },
        setJWT(state, jwt: string) {
            state.jwt = jwt
        },
        setLeftToolbarVisibility(state, visible: boolean) {
            state.isShowingLeftToolbar = visible
        },
        setTopToolbarVisibility(state, visible: boolean) {
            state.isShowingTopToolbar = visible
        },
        setModalVisibility(state, visible: boolean) {
            state.isShowingModal = visible
        },
        setSnackbarErrorVisibility(state, visible: boolean) {
            state.snackbarError = visible
        },
        setSnackbarErrorMessage(state, message: string) {
            state.snackbarErrorMessage = message
        },
        setSnackbarWarningMessage(state, message: string) {
            state.snackbarWarningMessage = message
        },
        setSnackbarWarningVisibility(state, visible: boolean) {
            state.snackbarWarning = visible
        },
    },
    actions: {
        setGlobalLoadingState(context, shouldLoad: boolean) {
            context.commit('setGlobalLoadingState', shouldLoad)
        },
        setJWT(context, jwt: string) {
            context.commit('setJWT', jwt)
        },
        setLeftToolbarVisibility(context, visible: boolean) {
            context.commit('setLeftToolbarVisibility', visible)
        },
        setModalVisibility(context, visible: boolean) {
            context.commit('setModalVisibility', visible)
        },
        setTopToolbarVisibility(context, visible: boolean) {
            context.commit('setTopToolbarVisibility', visible)
        },
        setSnackbarErrorVisibility(context, visible: boolean) {
            context.commit('setSnackbarErrorVisibility', visible)
        },
        setSnackbarErrorMessage(context, message: string) {
            context.commit('setSnackbarErrorMessage', message)
        },
        setSnackbarWarningMessage(context, message: string) {
            context.commit('setSnackbarWarningMessage', message)
        },
        setSnackbarWarningVisibility(context, visible: boolean) {
            context.commit('setSnackbarWarningVisibility', visible)
        },
    }
}