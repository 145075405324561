
























import { Vue, Component } from 'vue-property-decorator'

@Component
export default class ApplicationNavigationDrawer extends Vue {

  state = this.$store.state.appstate
  group = null

  mounted() {
    //Tetse
  }

  atualizarSituacaoToolbar(state: boolean) {
    this.$store.dispatch("appstate/setLeftToolbarVisibility", state)
  }

}

