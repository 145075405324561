import RouterMiddleware from "@/middleware/RouteMiddleware"
import Vue from "vue"
import VueRouter, { Route } from "vue-router"
import { Position, PositionResult } from "vue-router/types/router"
import RouteCollector from "./RouteCollector"

Vue.use(VueRouter)

const router = new VueRouter({
  routes: RouteCollector.routes(),
  scrollBehavior: function (to: Route, from: Route, savedPosition: void | Position) {
    if (to.path === '/planos') {
      setTimeout(() => {
        const element = document.getElementById(to.hash.replace('#', ''))
        if (element) element.scrollIntoView({ block: "start", behavior: "smooth" })
      }, 100)
    }
  }
})

RouterMiddleware.init(router)

export default router