




































import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO";
import LocalStorageProvider, {
  LocalStorageEnum,
} from "@/providers/LocalStorageProviders";
import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters({
      contratacao: "contratacaostate/contratacao",
    }),
  },
})
export default class ApplicationTopToolbar extends Vue {
  contratacao!: ContratacaoDTO;
  state = this.$store.state.appstate;
  navigationAberto = this.state.isShowingLeftToolbar;

  mounted() {
    //mounted
  }

  abrirNavigationDrawer() {
    this.navigationAberto = !this.state.isShowingLeftToolbar;
    this.$store.dispatch(
      "appstate/setLeftToolbarVisibility",
      this.navigationAberto
    );
  }

  logout() {
    LocalStorageProvider.remove(LocalStorageEnum.CONFIG_KEY);
    this.$store.dispatch("contratacaostate/setusuario_logado", false);
    this.$store.dispatch("contratacaostate/cleanInformacoesResponsavel");
    // this.$store.dispatch("contratacaostate/cleanInformacoesEmpresa")
  }
}
