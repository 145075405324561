import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: "#e3e3e3",
        accent: "#49AD6A",
      },
      light: {
        primary: "#108592",
        primaryLight: "#4bc3ca",
        success: "#66bb6a",
        error: "#ef5350",
        colorWhite: "#ffffff",
        colorYellow: "#ffcc29",
        textSecondaryOnBackground: "#757575",
      },
    },
  },
});
