










import { Vue, Component } from 'vue-property-decorator'

@Component
export default class ApplicationErrorSnackbar extends Vue {

  state = this.$store.state.appstate

  closeSnackBar() {
    this.$store.dispatch("appstate/setSnackbarErrorVisibility", false)
  }

}

