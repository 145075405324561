export default class LocalStorageProvider {

    public static retrieve<Type>(key: LocalStorageEnum): Type {
        const objectAsString: string = localStorage.getItem(key) ?? '{}'
        return JSON.parse(objectAsString)
    }

    public static store<Type>(config: Type, key: LocalStorageEnum) {
        localStorage.setItem(key, JSON.stringify(config))
    }

    public static remove(key: LocalStorageEnum) {
        localStorage.removeItem(key)
    }

}

export enum LocalStorageEnum {
    CONFIG_KEY = "config",
}