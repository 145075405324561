import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
import store from "./store"
import VueTheMask from 'vue-the-mask'
import money from 'v-money'

Vue.config.productionTip = false
Vue.config.devtools = process.env.VUE_APP_DEV_TOOLS === 'Sim' ? true : false

Vue.use(VueTheMask, { masked: false })

Vue.use(money, { precision: 4 })

Vue.filter('currency', function (value: number) {
  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
})

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app")
