// import LocalStorageProvider from "@/providers/LocalStorageProviders"
import VueRouter from "vue-router"

class RouteMiddleware {

    public static init(router: VueRouter) {
        // router.beforeEach((to, _, next) => {
        //     to.name !== 'Login' && !LocalStorageProvider.retrieve().jwt ? next({ name: 'Login' }) : next()
        // })
    }
}

export default RouteMiddleware 