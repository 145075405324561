import CotacaoCompletaDTO, { CotacaoCarroceriaDTO, CotacaoConviteDTO, CotacaoProdutoDTO } from "@/domains/cotacao/CotacaoCompletaDTO"
import store from "@/store"
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators"

@Module({
    namespaced: true,
    store,
})

class CotacaoModule extends VuexModule {

    private cotacaoState: CotacaoCompletaDTO = {
        cotacao_frete: {
            id_tomador: 0,
            codigo_referencia: "",
            tipo_cotacao: "TOMADA_PRECO",
            entrega_observacao: "",
            coleta_observacao: "",
            cadencia: 1,
            quantidade_fretes: 1,
            frete_em_lote: "Não",
            data_inicio_cotacao: "",
            data_termino_cotacao: "",
            data_inicio_vigencia: "",
            data_termino_vigencia: "",
            observacao_transportadora: "",
            id_ponto_coleta: 0,
            coleta_uf: "",
            coleta_municipio: "",
            id_ponto_entrega: 0,
            entrega_uf: "",
            entrega_municipio: "",

            valor: 0,
            valor_text: "",

            peso: 0,
            tipo_preco_frete: "Não",
            situacao: "ABERTA",
            tipo_precificacao: "ALL_IN",
            valor_habilitado: "Sim",
            vale_pedagio_habilitado: "Não",

            vale_pedagio_valor: 0,
            vale_pedagio_valor_text: "",

            ad_valorem_habilitado: "Não",

            ad_valorem_valor: 0,
            ad_valorem_valor_text: "",

            estimativa_entrega_habilitado: "Não",
            estimativa_entrega_dias: 0,
            publica: "Não",

            //Usar estar propriedades apenas na tela 
            _data_inicio_cotacao: "",
            _data_termino_cotacao: "",
            _data_inicio_vigencia: "",
            _data_termino_vigencia: "",
            _hora_inicio_cotacao: "",
            _hora_termino_cotacao: "",
            _hora_inicio_vigencia: "",
            _hora_termino_vigencia: "",
        },
        convites: [],
        produtos: [],
        carrocerias: [],
    }


    get cotacaoCompleta() {
        return this.cotacaoState
    }


    @Mutation
    _idtomador(idtomador: number) {
        this.cotacaoState.cotacao_frete.id_tomador = idtomador
    }

    @Action({ commit: "_idtomador" })
    idtomador(idtomador: number) {
        return idtomador
    }


    @Mutation
    _codigoreferencia(codigoreferencia: string) {
        this.cotacaoState.cotacao_frete.codigo_referencia = codigoreferencia
    }

    @Action({ commit: "_codigoreferencia" })
    codigoreferencia(codigoreferencia: string) {
        return codigoreferencia
    }


    @Mutation
    _tipocotacao(tipocotacao: string) {
        this.cotacaoState.cotacao_frete.tipo_cotacao = tipocotacao
    }

    @Action({ commit: "_tipocotacao" })
    tipocotacao(tipocotacao: string) {
        return tipocotacao
    }


    @Mutation
    _entregaobservacao(entregaobservacao: string) {
        this.cotacaoState.cotacao_frete.entrega_observacao = entregaobservacao
    }

    @Action({ commit: "_entregaobservacao" })
    entregaobservacao(entregaobservacao: string) {
        return entregaobservacao
    }


    @Mutation
    _coletaobservacao(coletaobservacao: string) {
        this.cotacaoState.cotacao_frete.coleta_observacao = coletaobservacao
    }

    @Action({ commit: "_coletaobservacao" })
    coletaobservacao(coletaobservacao: string) {
        return coletaobservacao
    }


    @Mutation
    _cadencia(cadencia: number) {
        this.cotacaoState.cotacao_frete.cadencia = cadencia
    }

    @Action({ commit: "_cadencia" })
    cadencia(cadencia: number) {
        return cadencia
    }


    @Mutation
    _quantidadefretes(quantidadefretes: number) {
        this.cotacaoState.cotacao_frete.quantidade_fretes = quantidadefretes
    }

    @Action({ commit: "_quantidadefretes" })
    quantidadefretes(quantidadefretes: number) {
        return quantidadefretes
    }


    @Mutation
    _freteem_lote(freteem_lote: string) {
        this.cotacaoState.cotacao_frete.frete_em_lote = freteem_lote
    }

    @Action({ commit: "_freteem_lote" })
    freteem_lote(freteem_lote: string) {
        return freteem_lote
    }


    @Mutation
    _datainicio_cotacao(datainicio_cotacao: string) {
        this.cotacaoState.cotacao_frete.data_inicio_cotacao = datainicio_cotacao
    }

    @Action({ commit: "_datainicio_cotacao" })
    datainicio_cotacao(datainicio_cotacao: string) {
        return datainicio_cotacao
    }


    @Mutation
    _datatermino_cotacao(datatermino_cotacao: string) {
        this.cotacaoState.cotacao_frete.data_termino_cotacao = datatermino_cotacao
    }

    @Action({ commit: "_datatermino_cotacao" })
    datatermino_cotacao(datatermino_cotacao: string) {
        return datatermino_cotacao
    }


    @Mutation
    _datainicio_vigencia(datainicio_vigencia: string) {
        this.cotacaoState.cotacao_frete.data_inicio_vigencia = datainicio_vigencia
    }

    @Action({ commit: "_datainicio_vigencia" })
    datainicio_vigencia(datainicio_vigencia: string) {
        return datainicio_vigencia
    }


    @Mutation
    _datatermino_vigencia(datatermino_vigencia: string) {
        this.cotacaoState.cotacao_frete.data_termino_vigencia = datatermino_vigencia
    }

    @Action({ commit: "_datatermino_vigencia" })
    datatermino_vigencia(datatermino_vigencia: string) {
        return datatermino_vigencia
    }


    @Mutation
    _observacaotransportadora(observacaotransportadora: string) {
        this.cotacaoState.cotacao_frete.observacao_transportadora = observacaotransportadora
    }

    @Action({ commit: "_observacaotransportadora" })
    observacaotransportadora(observacaotransportadora: string) {
        return observacaotransportadora
    }


    @Mutation
    _idponto_coleta(idponto_coleta: number) {
        this.cotacaoState.cotacao_frete.id_ponto_coleta = idponto_coleta
    }

    @Action({ commit: "_idponto_coleta" })
    idponto_coleta(idponto_coleta: number) {
        return idponto_coleta
    }


    @Mutation
    _coletauf(coletauf: string) {
        this.cotacaoState.cotacao_frete.coleta_uf = coletauf
    }

    @Action({ commit: "_coletauf" })
    coletauf(coletauf: string) {
        return coletauf
    }


    @Mutation
    _coletamunicipio(coletamunicipio: string) {
        this.cotacaoState.cotacao_frete.coleta_municipio = coletamunicipio
    }

    @Action({ commit: "_coletamunicipio" })
    coletamunicipio(coletamunicipio: string) {
        return coletamunicipio
    }


    @Mutation
    _idponto_entrega(idponto_entrega: number) {
        this.cotacaoState.cotacao_frete.id_ponto_entrega = idponto_entrega
    }

    @Action({ commit: "_idponto_entrega" })
    idponto_entrega(idponto_entrega: number) {
        return idponto_entrega
    }


    @Mutation
    _entregauf(entregauf: string) {
        this.cotacaoState.cotacao_frete.entrega_uf = entregauf
    }

    @Action({ commit: "_entregauf" })
    entregauf(entregauf: string) {
        return entregauf
    }


    @Mutation
    _entregamunicipio(entregamunicipio: string) {
        this.cotacaoState.cotacao_frete.entrega_municipio = entregamunicipio
    }

    @Action({ commit: "_entregamunicipio" })
    entregamunicipio(entregamunicipio: string) {
        return entregamunicipio
    }


    @Mutation
    _valor(valor: string) {
        this.cotacaoState.cotacao_frete.valor_text = valor
    }

    @Action({ commit: "_valor" })
    valor(valor: string) {
        return valor
    }


    @Mutation
    _peso(peso: number) {
        this.cotacaoState.cotacao_frete.peso = peso
    }

    @Action({ commit: "_peso" })
    peso(peso: number) {
        return peso
    }


    @Mutation
    _tipopreco_frete(tipopreco_frete: string) {
        this.cotacaoState.cotacao_frete.tipo_preco_frete = tipopreco_frete
    }

    @Action({ commit: "_tipopreco_frete" })
    tipopreco_frete(tipopreco_frete: string) {
        return tipopreco_frete
    }


    @Mutation
    _situacao(situacao: string) {
        this.cotacaoState.cotacao_frete.situacao = situacao
    }

    @Action({ commit: "_situacao" })
    situacao(situacao: string) {
        return situacao
    }


    @Mutation
    _tipoprecificacao(tipoprecificacao: string) {
        this.cotacaoState.cotacao_frete.tipo_precificacao = tipoprecificacao
    }

    @Action({ commit: "_tipoprecificacao" })
    tipoprecificacao(tipoprecificacao: string) {
        return tipoprecificacao
    }


    @Mutation
    _valorhabilitado(valorhabilitado: string) {
        this.cotacaoState.cotacao_frete.valor_habilitado = valorhabilitado
    }

    @Action({ commit: "_valorhabilitado" })
    valorhabilitado(valorhabilitado: string) {
        return valorhabilitado
    }


    @Mutation
    _valepedagio_habilitado(valepedagio_habilitado: string) {
        this.cotacaoState.cotacao_frete.vale_pedagio_habilitado = valepedagio_habilitado
    }

    @Action({ commit: "_valepedagio_habilitado" })
    valepedagio_habilitado(valepedagio_habilitado: string) {
        return valepedagio_habilitado
    }


    @Mutation
    _valepedagio_valor(valepedagio_valor: string) {
        this.cotacaoState.cotacao_frete.vale_pedagio_valor_text = valepedagio_valor
    }

    @Action({ commit: "_valepedagio_valor" })
    valepedagio_valor(valepedagio_valor: string) {
        return valepedagio_valor
    }


    @Mutation
    _advalorem_habilitado(advalorem_habilitado: string) {
        this.cotacaoState.cotacao_frete.ad_valorem_habilitado = advalorem_habilitado
    }

    @Action({ commit: "_advalorem_habilitado" })
    advalorem_habilitado(advalorem_habilitado: string) {
        return advalorem_habilitado
    }


    @Mutation
    _advalorem_valor(advalorem_valor: string) {
        this.cotacaoState.cotacao_frete.ad_valorem_valor_text = advalorem_valor
    }

    @Action({ commit: "_advalorem_valor" })
    advalorem_valor(advalorem_valor: string) {
        return advalorem_valor
    }


    @Mutation
    _estimativaentrega_habilitado(estimativaentrega_habilitado: string) {
        this.cotacaoState.cotacao_frete.estimativa_entrega_habilitado = estimativaentrega_habilitado
    }

    @Action({ commit: "_estimativaentrega_habilitado" })
    estimativaentrega_habilitado(estimativaentrega_habilitado: string) {
        return estimativaentrega_habilitado
    }


    @Mutation
    _estimativaentrega_dias(estimativaentrega_dias: number) {
        this.cotacaoState.cotacao_frete.estimativa_entrega_dias = estimativaentrega_dias
    }

    @Action({ commit: "_estimativaentrega_dias" })
    estimativaentrega_dias(estimativaentrega_dias: number) {
        return estimativaentrega_dias
    }


    @Mutation
    _publica(publica: string) {
        this.cotacaoState.cotacao_frete.publica = publica
    }

    @Action({ commit: "_publica" })
    publica(publica: string) {
        return publica
    }

    //Carroceria

    @Mutation
    _setCarrocerias(carroceria: CotacaoCarroceriaDTO[]) {
        this.cotacaoState.carrocerias = carroceria
    }

    @Action({ commit: "_setCarrocerias" })
    setCarrocerias(carroceria: CotacaoCarroceriaDTO) {
        return carroceria
    }

    //Produto

    @Mutation
    _setProduto(produto: CotacaoProdutoDTO) {
        this.cotacaoState.produtos.push(produto)
    }

    @Action({ commit: "_setProduto" })
    setProduto(produto: CotacaoProdutoDTO) {
        return produto
    }

    @Mutation
    _deleteProduto(index: number) {
        this.cotacaoState.produtos.splice(index, 1)
    }

    @Action({ commit: "_deleteProduto" })
    deleteProduto(index: number) {
        return index
    }

    //Convites

    @Mutation
    _setConvite(transportadoras: CotacaoConviteDTO[]) {
        this.cotacaoState.convites = transportadoras
    }

    @Action({ commit: "_setConvite" })
    setConvite(transportadoras: CotacaoConviteDTO[]) {
        return transportadoras
    }

    @Mutation
    _deleteConvite(index: number) {
        this.cotacaoState.convites.splice(index, 1)
    }

    @Action({ commit: "_deleteConvite" })
    deleteConvite(index: number) {
        return index
    }

    // Datas para usar na tela 


    @Mutation
    _datainicio_cotacao_tela(datainicio_cotacao: string) {
        this.cotacaoState.cotacao_frete._data_inicio_cotacao = datainicio_cotacao
    }

    @Action({ commit: "_datainicio_cotacao_tela" })
    datainicio_cotacao_tela(datainicio_cotacao: string) {
        return datainicio_cotacao
    }


    @Mutation
    _datatermino_cotacao_tela(datatermino_cotacao: string) {
        this.cotacaoState.cotacao_frete._data_termino_cotacao = datatermino_cotacao
    }

    @Action({ commit: "_datatermino_cotacao_tela" })
    datatermino_cotacao_tela(datatermino_cotacao: string) {
        return datatermino_cotacao
    }


    @Mutation
    _datainicio_vigencia_tela(datainicio_vigencia: string) {
        this.cotacaoState.cotacao_frete._data_inicio_vigencia = datainicio_vigencia
    }

    @Action({ commit: "_datainicio_vigencia_tela" })
    datainicio_vigencia_tela(datainicio_vigencia: string) {
        return datainicio_vigencia
    }


    @Mutation
    _datatermino_vigencia_tela(datatermino_vigencia: string) {
        this.cotacaoState.cotacao_frete._data_termino_vigencia = datatermino_vigencia
    }

    @Action({ commit: "_datatermino_vigencia_tela" })
    datatermino_vigencia_tela(datatermino_vigencia: string) {
        return datatermino_vigencia
    }

    @Mutation
    _horainicio_cotacao_tela(horainicio_cotacao: string) {
        this.cotacaoState.cotacao_frete._hora_inicio_cotacao = horainicio_cotacao
    }

    @Action({ commit: "_horainicio_cotacao_tela" })
    horainicio_cotacao_tela(horainicio_cotacao: string) {
        return horainicio_cotacao
    }


    @Mutation
    _horatermino_cotacao_tela(horatermino_cotacao: string) {
        this.cotacaoState.cotacao_frete._hora_termino_cotacao = horatermino_cotacao
    }

    @Action({ commit: "_horatermino_cotacao_tela" })
    horatermino_cotacao_tela(horatermino_cotacao: string) {
        return horatermino_cotacao
    }


    // @Mutation
    // _horainicio_vigencia_tela(horainicio_vigencia: string) {
    //     this.cotacaoState.cotacao_frete._hora_inicio_vigencia = horainicio_vigencia
    // }

    // @Action({ commit: "_horainicio_vigencia_tela" })
    // horainicio_vigencia_tela(horainicio_vigencia: string) {
    //     return horainicio_vigencia
    // }


    // @Mutation
    // _horatermino_vigencia_tela(horatermino_vigencia: string) {
    //     this.cotacaoState.cotacao_frete._hora_termino_vigencia = horatermino_vigencia
    // }

    // @Action({ commit: "_horatermino_vigencia_tela" })
    // horatermino_vigencia_tela(horatermino_vigencia: string) {
    //     return horatermino_vigencia
    // }






}

export default CotacaoModule



