export enum PlanosEnum {
    SPOT = 'SPOT',
    STANDARD = 'STANDARD',
    ADVANCED = 'ADVANCED',
    PREMIUM = 'PREMIUM',
    GRATIS = 'GRATIS',
    TRANSPORTADORA_STANDARD = 'TRANSPORTADORA_STANDARD',
    TRANSPORTADORA_ADVANCED = 'TRANSPORTADORA_ADVANCED',
    TRANSPORTADORA_PREMIUM = 'TRANSPORTADORA_PREMIUM',
    TRIAL = 'TRIAL',
    NA = 'NA',
}

export enum PlanosRamosEnum {
    TRANSPORTADORA = 'TRANSPORTADORA',
    TOMADOR = 'TOMADOR',
}

