










import { Vue, Component, Watch } from 'vue-property-decorator'

@Component
export default class ApplicationErrorSnackbar extends Vue {

  state = this.$store.state.appstate

  progress = 0
  timer = 0
  i = 0

  @Watch('state.snackbarWarning')
  onChangeSnackbarError(newValue: boolean) {

    this.i = 0

    if (newValue) {

      this.timer = setInterval(() => {
        this.i++

        if (this.i >= 4700) return
        this.progress = (100 * this.i) / 4700

      }, 10)

    } else {
      clearInterval(this.timer)
    }
  }
}

