import { FreteCarroceriaDTO, FreteCompletoDTO, FreteContatoWhatsAppDTO, FreteProdutoDTO } from "@/domains/frete/FreteDTO"
import store from "@/store"
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators"

@Module({
    namespaced: true,
    store,
})

class FreteModule extends VuexModule {

    private freteState: FreteCompletoDTO = {
        Frete: {
            id_tomador: 0,
            id_cadastrante: 0,
            cadastrante: "TOMADOR",
            roteiro: "",
            agrupador_total_registros: 1,
            cadastro_lote: "Não",
            codigo_referencia: "",
            frete_motorista: "Sim",
            frota_propria: "Não",
            custo: 0,
            motorista_cpf: "",
            placa: "",
            motorista_telefone: "",
            frete_motorista_valor: 0,
            frete_motorista_valor_text: "",
            frete_transportadora: "Sim",
            frete_transportadora_divulgacao: "Parceiros",
            frete_transportadora_valor: 0,
            frete_transportadora_valor_text: "",
            imposto_incluso: "Não",
            modalidade_frete: "CIF",
            pedagio_incluso: "Não",
            peso: 0,
            peso_exato: "Indeterminado",
            peso_unidade_medida: "KG",
            tipo_cadastro: "MANUAL",
            tipo_operacao: "VENDA",
            venda_por_unidade: "Sim",
            transportadora_nome: "",
            transportadora_cnpj: "",
            transportadora_telefone: ""
        },
        Entrega: {
            numero: "",
            endereco: "",
            bairro: "",
            municipio: "",
            uf: "",
            latitude: "",
            longitude: "",
            municipio_codigo_ibge: "",
            raio_cerca_geolocalizacao: 15,
            entrega: "DATA ESPECÍFICA",
            data_prevista: "",
            entrega_dia_todo: "Sim",
        },
        Cliente: {
            cnpj: "",
            nome: "",
            email: "",
            telefone_celular: ""
        },
        Coleta: {
            numero: "",
            endereco: "",
            bairro: "",
            municipio: "",
            uf: "",
            latitude: "",
            longitude: "",
            municipio_codigo_ibge: "",
            raio_cerca_geolocalizacao: 5,
            coleta_imediata: "",
            data_prevista: "",
            coleta_dia_todo: "Sim"
        },
        Embarcador: {
            cnpj: "",
            nome: "",
            email: "",
            telefone_celular: ""
        },
        ContatoWhatsApp: [],
        Tomador: {
            cnpj: "",
            nome: "",
            email: ""
        },
        Carrocerias: [],
        Produtos: [],
        data_evento: ""
    }


    get freteCompleto() {
        return this.freteState
    }


    //Frete

    @Mutation
    _setId_tomador(id_tomador: number) {
        this.freteState.Frete.id_tomador = id_tomador
    }

    @Action({ commit: "_setId_tomador" })
    setId_tomador(id_tomador: number) {
        return id_tomador
    }

    @Mutation
    _setId_cadastrante(id_cadastrante: number) {
        this.freteState.Frete.id_cadastrante = id_cadastrante
    }

    @Action({ commit: "_setId_cadastrante" })
    setId_cadastrante(id_cadastrante: number) {
        return id_cadastrante
    }
    @Mutation
    _setCadastrante(cadastrante: string) {
        this.freteState.Frete.cadastrante = cadastrante
    }

    @Action({ commit: "_setCadastrante" })
    setCadastrante(cadastrante: string) {
        return cadastrante
    }
    @Mutation
    _setRoteiro(roteiro: string) {
        this.freteState.Frete.roteiro = roteiro
    }

    @Action({ commit: "_setRoteiro" })
    setRoteiro(roteiro: string) {
        return roteiro
    }
    @Mutation
    _setAgrupador_total_registros(agrupador_total_registros: number) {
        this.freteState.Frete.agrupador_total_registros = agrupador_total_registros
    }

    @Action({ commit: "_setAgrupador_total_registros" })
    setAgrupador_total_registros(agrupador_total_registros: number) {
        return agrupador_total_registros
    }
    @Mutation
    _setCadastro_lote(cadastro_lote: string) {
        this.freteState.Frete.cadastro_lote = cadastro_lote
    }

    @Action({ commit: "_setCadastro_lote" })
    setCadastro_lote(cadastro_lote: string) {
        return cadastro_lote
    }
    @Mutation
    _setCodigo_referencia(codigo_referencia: string) {
        this.freteState.Frete.codigo_referencia = codigo_referencia
    }

    @Action({ commit: "_setCodigo_referencia" })
    setCodigo_referencia(codigo_referencia: string) {
        return codigo_referencia
    }
    @Mutation
    _setFrete_motorista(frete_motorista: string) {
        this.freteState.Frete.frete_motorista = frete_motorista
    }

    @Action({ commit: "_setFrete_motorista" })
    setFrete_motorista(frete_motorista: string) {
        return frete_motorista
    }
    @Mutation
    _setFrota_propria(frota_propria: string) {
        this.freteState.Frete.frota_propria = frota_propria
    }

    @Action({ commit: "_setFrota_propria" })
    setFrota_propria(frota_propria: string) {
        return frota_propria
    }
    @Mutation
    _setCusto(custo: number) {
        this.freteState.Frete.custo = custo
    }

    @Action({ commit: "_setCusto" })
    setCusto(custo: number) {
        return custo
    }
    @Mutation
    _setMotorista_cpf(motorista_cpf: string) {
        this.freteState.Frete.motorista_cpf = motorista_cpf
    }

    @Action({ commit: "_setMotorista_cpf" })
    setMotorista_cpf(motorista_cpf: string) {
        return motorista_cpf
    }
    @Mutation
    _setPlaca(placa: string) {
        this.freteState.Frete.placa = placa
    }

    @Action({ commit: "_setPlaca" })
    setPlaca(placa: string) {
        return placa
    }
    @Mutation
    _setMotorista_telefone(motorista_telefone: string) {
        this.freteState.Frete.motorista_telefone = motorista_telefone
    }

    @Action({ commit: "_setMotorista_telefone" })
    setMotorista_telefone(motorista_telefone: string) {
        return motorista_telefone
    }
    @Mutation
    _setFrete_motorista_valor(frete_motorista_valor: string) {
        this.freteState.Frete.frete_motorista_valor_text = frete_motorista_valor
    }

    @Action({ commit: "_setFrete_motorista_valor" })
    setFrete_motorista_valor(frete_motorista_valor: string) {
        return frete_motorista_valor
    }

    @Mutation
    _setFrete_transportadora(frete_transportadora: string) {
        this.freteState.Frete.frete_transportadora = frete_transportadora
    }

    @Action({ commit: "_setFrete_transportadora" })
    setFrete_transportadora(frete_transportadora: string) {
        return frete_transportadora
    }
    @Mutation
    _setFrete_transportadora_divulgacao(frete_transportadora_divulgacao: string) {
        this.freteState.Frete.frete_transportadora_divulgacao = frete_transportadora_divulgacao
    }

    @Action({ commit: "_setFrete_transportadora_divulgacao" })
    setFrete_transportadora_divulgacao(frete_transportadora_divulgacao: string) {
        return frete_transportadora_divulgacao
    }
    @Mutation
    _setFrete_transportadora_valor(frete_transportadora_valor: string) {
        this.freteState.Frete.frete_transportadora_valor_text = frete_transportadora_valor
    }

    @Action({ commit: "_setFrete_transportadora_valor" })
    setFrete_transportadora_valor(frete_transportadora_valor: string) {
        return frete_transportadora_valor
    }
    @Mutation
    _setImposto_incluso(imposto_incluso: string) {
        this.freteState.Frete.imposto_incluso = imposto_incluso
    }

    @Action({ commit: "_setImposto_incluso" })
    setImposto_incluso(imposto_incluso: string) {
        return imposto_incluso
    }
    @Mutation
    _setModalidade_frete(modalidade_frete: string) {
        this.freteState.Frete.modalidade_frete = modalidade_frete
    }

    @Action({ commit: "_setModalidade_frete" })
    setModalidade_frete(modalidade_frete: string) {
        return modalidade_frete
    }
    @Mutation
    _setPedagio_incluso(pedagio_incluso: string) {
        this.freteState.Frete.pedagio_incluso = pedagio_incluso
    }

    @Action({ commit: "_setPedagio_incluso" })
    setPedagio_incluso(pedagio_incluso: string) {
        return pedagio_incluso
    }
    @Mutation
    _setPeso(peso: number) {
        this.freteState.Frete.peso = peso
    }

    @Action({ commit: "_setPeso" })
    setPeso(peso: number) {
        return peso
    }
    @Mutation
    _setPeso_exato(peso_exato: string) {
        this.freteState.Frete.peso_exato = peso_exato
    }

    @Action({ commit: "_setPeso_exato" })
    setPeso_exato(peso_exato: string) {
        return peso_exato
    }
    @Mutation
    _setPeso_unidade_medida(peso_unidade_medida: string) {
        this.freteState.Frete.peso_unidade_medida = peso_unidade_medida
    }

    @Action({ commit: "_setPeso_unidade_medida" })
    setPeso_unidade_medida(peso_unidade_medida: string) {
        return peso_unidade_medida
    }
    @Mutation
    _setTipo_cadastro(tipo_cadastro: string) {
        this.freteState.Frete.tipo_cadastro = tipo_cadastro
    }

    @Action({ commit: "_setTipo_cadastro" })
    setTipo_cadastro(tipo_cadastro: string) {
        return tipo_cadastro
    }
    @Mutation
    _setTipo_operacao(tipo_operacao: string) {
        this.freteState.Frete.tipo_operacao = tipo_operacao
    }

    @Action({ commit: "_setTipo_operacao" })
    setTipo_operacao(tipo_operacao: string) {
        return tipo_operacao
    }
    @Mutation
    _setVenda_por_unidade(venda_por_unidade: string) {
        this.freteState.Frete.venda_por_unidade = venda_por_unidade
    }

    @Action({ commit: "_setVenda_por_unidade" })
    setVenda_por_unidade(venda_por_unidade: string) {
        return venda_por_unidade
    }
    @Mutation
    _setTransportadora_nome(transportadora_nome: string) {
        this.freteState.Frete.transportadora_nome = transportadora_nome
    }

    @Action({ commit: "_setTransportadora_nome" })
    setTransportadora_nome(transportadora_nome: string) {
        return transportadora_nome
    }
    @Mutation
    _setTransportadora_cnpj(transportadora_cnpj: string) {
        this.freteState.Frete.transportadora_cnpj = transportadora_cnpj
    }

    @Action({ commit: "_setTransportadora_cnpj" })
    setTransportadora_cnpj(transportadora_cnpj: string) {
        return transportadora_cnpj
    }
    @Mutation
    _setTransportadora_telefone(transportadora_telefone: string) {
        this.freteState.Frete.transportadora_telefone = transportadora_telefone
    }

    @Action({ commit: "_setTransportadora_telefone" })
    setTransportadora_telefone(transportadora_telefone: string) {
        return transportadora_telefone
    }

    //Entrega

    @Mutation
    _setNumero(numero: string) {
        this.freteState.Entrega.numero = numero
    }

    @Action({ commit: "_setNumero" })
    setNumero(numero: string) {
        return numero
    }

    @Mutation
    _setEndereco(endereco: string) {
        this.freteState.Entrega.endereco = endereco
    }

    @Action({ commit: "_setEndereco" })
    setEndereco(endereco: string) {
        return endereco
    }

    @Mutation
    _setBairro(bairro: string) {
        this.freteState.Entrega.bairro = bairro
    }

    @Action({ commit: "_setBairro" })
    setBairro(bairro: string) {
        return bairro
    }

    @Mutation
    _setMunicipio(municipio: string) {
        this.freteState.Entrega.municipio = municipio
    }

    @Action({ commit: "_setMunicipio" })
    setMunicipio(municipio: string) {
        return municipio
    }

    @Mutation
    _setUf(uf: string) {
        this.freteState.Entrega.uf = uf
    }

    @Action({ commit: "_setUf" })
    setUf(uf: string) {
        return uf
    }

    @Mutation
    _setLatitude(latitude: string) {
        this.freteState.Entrega.latitude = latitude
    }

    @Action({ commit: "_setLatitude" })
    setLatitude(latitude: string) {
        return latitude
    }

    @Mutation
    _setLongitude(longitude: string) {
        this.freteState.Entrega.longitude = longitude
    }

    @Action({ commit: "_setLongitude" })
    setLongitude(longitude: string) {
        return longitude
    }

    @Mutation
    _setMunicipio_codigo_ibge(municipio_codigo_ibge: string) {
        this.freteState.Entrega.municipio_codigo_ibge = municipio_codigo_ibge
    }

    @Action({ commit: "_setMunicipio_codigo_ibge" })
    setMunicipio_codigo_ibge(municipio_codigo_ibge: string) {
        return municipio_codigo_ibge
    }

    @Mutation
    _setData_entrega(data_entrega: null) {
        this.freteState.Entrega.data_entrega = data_entrega
    }

    @Action({ commit: "_setData_entrega" })
    setData_entrega(data_entrega: null) {
        return data_entrega
    }

    @Mutation
    _setRaio_cerca_geolocalizacao(raio_cerca_geolocalizacao: number) {
        this.freteState.Entrega.raio_cerca_geolocalizacao = raio_cerca_geolocalizacao
    }

    @Action({ commit: "_setRaio_cerca_geolocalizacao" })
    setRaio_cerca_geolocalizacao(raio_cerca_geolocalizacao: number) {
        return raio_cerca_geolocalizacao
    }

    @Mutation
    _setEntrega(entrega: string) {
        this.freteState.Entrega.entrega = entrega
    }

    @Action({ commit: "_setEntrega" })
    setEntrega(entrega: string) {
        return entrega
    }

    @Mutation
    _setData_prevista(data_prevista: string) {
        this.freteState.Entrega.data_prevista = data_prevista
    }

    @Action({ commit: "_setData_prevista" })
    setData_prevista(data_prevista: string) {
        return data_prevista
    }

    @Mutation
    _setEntregaDiaTodo(entregaDiaTodo: string) {
        this.freteState.Entrega.entrega_dia_todo = entregaDiaTodo
    }

    @Action({ commit: "_setEntregaDiaTodo" })
    setEntregaDiaTodo(entregaDiaTodo: string) {
        return entregaDiaTodo
    }

    //Cliente

    @Mutation
    _setCnpj(cnpj: string) {
        this.freteState.Cliente.cnpj = cnpj
    }

    @Action({ commit: "_setCnpj" })
    setCnpj(cnpj: string) {
        return cnpj
    }

    @Mutation
    _setNome(nome: string) {
        this.freteState.Cliente.nome = nome
    }

    @Action({ commit: "_setNome" })
    setNome(nome: string) {
        return nome
    }

    @Mutation
    _setEmail(email: string) {
        this.freteState.Cliente.email = email
    }

    @Action({ commit: "_setEmail" })
    setEmail(email: string) {
        return email
    }

    @Mutation
    _setTelefone_celular(telefone_celular: string) {
        this.freteState.Cliente.telefone_celular = telefone_celular
    }

    @Action({ commit: "_setTelefone_celular" })
    setTelefone_celular(telefone_celular: string) {
        return telefone_celular
    }

    //Coleta

    @Mutation
    _setColetaNumero(numero: string) {
        this.freteState.Coleta.numero = numero
    }

    @Action({ commit: "_setColetaNumero" })
    setColetaNumero(numero: string) {
        return numero
    }

    @Mutation
    _setColetaEndereco(endereco: string) {
        this.freteState.Coleta.endereco = endereco
    }

    @Action({ commit: "_setColetaEndereco" })
    setColetaEndereco(endereco: string) {
        return endereco
    }

    @Mutation
    _setColetaBairro(bairro: string) {
        this.freteState.Coleta.bairro = bairro
    }

    @Action({ commit: "_setColetaBairro" })
    setColetaBairro(bairro: string) {
        return bairro
    }

    @Mutation
    _setColetaMunicipio(municipio: string) {
        this.freteState.Coleta.municipio = municipio
    }

    @Action({ commit: "_setColetaMunicipio" })
    setColetaMunicipio(municipio: string) {
        return municipio
    }

    @Mutation
    _setColetaUf(uf: string) {
        this.freteState.Coleta.uf = uf
    }

    @Action({ commit: "_setColetaUf" })
    setColetaUf(uf: string) {
        return uf
    }

    @Mutation
    _setColetaLatitude(latitude: string) {
        this.freteState.Coleta.latitude = latitude
    }

    @Action({ commit: "_setColetaLatitude" })
    setColetaLatitude(latitude: string) {
        return latitude
    }

    @Mutation
    _setColetaLongitude(longitude: string) {
        this.freteState.Coleta.longitude = longitude
    }

    @Action({ commit: "_setColetaLongitude" })
    setColetaLongitude(longitude: string) {
        return longitude
    }

    @Mutation
    _setColetaMunicipio_codigo_ibge(municipio_codigo_ibge: string) {
        this.freteState.Coleta.municipio_codigo_ibge = municipio_codigo_ibge
    }

    @Action({ commit: "_setColetaMunicipio_codigo_ibge" })
    setColetaMunicipio_codigo_ibge(municipio_codigo_ibge: string) {
        return municipio_codigo_ibge
    }

    @Mutation
    _setColetaRaio_cerca_geolocalizacao(raio_cerca_geolocalizacao: number) {
        this.freteState.Coleta.raio_cerca_geolocalizacao = raio_cerca_geolocalizacao
    }

    @Action({ commit: "_setColetaRaio_cerca_geolocalizacao" })
    setColetaRaio_cerca_geolocalizacao(raio_cerca_geolocalizacao: number) {
        return raio_cerca_geolocalizacao
    }

    @Mutation
    _setColeta_imediata(coleta_imediata: string) {
        this.freteState.Coleta.coleta_imediata = coleta_imediata
    }

    @Action({ commit: "_setColeta_imediata" })
    setColeta_imediata(coleta_imediata: string) {
        return coleta_imediata
    }

    @Mutation
    _setColetaData_prevista(data_prevista: string) {
        this.freteState.Coleta.data_prevista = data_prevista
    }

    @Action({ commit: "_setColetaData_prevista" })
    setColetaData_prevista(data_prevista: string) {
        return data_prevista
    }

    @Mutation
    _setColetaDiaTodo(coletaDiaTodo: string) {
        this.freteState.Coleta.coleta_dia_todo = coletaDiaTodo
    }

    @Action({ commit: "_setColetaDiaTodo" })
    setColetaDiaTodo(coletaDiaTodo: string) {
        return coletaDiaTodo
    }

    //Embarcador

    @Mutation
    _setEmbarcadorCnpj(embarcadorCnpj: string) {
        this.freteState.Embarcador.cnpj = embarcadorCnpj
    }

    @Action({ commit: "_setEmbarcadorCnpj" })
    setEmbarcadorCnpj(embarcadorCnpj: string) {
        return embarcadorCnpj
    }

    @Mutation
    _setEmbarcadorNome(embarcadorNome: string) {
        this.freteState.Embarcador.nome = embarcadorNome
    }

    @Action({ commit: "_setEmbarcadorNome" })
    setEmbarcadorNome(embarcadorNome: string) {
        return embarcadorNome
    }

    @Mutation
    _setEmbarcadorEmail(embarcadorEmail: string) {
        this.freteState.Embarcador.email = embarcadorEmail
    }

    @Action({ commit: "_setEmbarcadorEmail" })
    setEmbarcadorEmail(embarcadorEmail: string) {
        return embarcadorEmail
    }

    @Mutation
    _setEmbarcadorTelefoneCelular(embarcadorTelefoneCelular: string) {
        this.freteState.Embarcador.telefone_celular = embarcadorTelefoneCelular
    }

    @Action({ commit: "_setEmbarcadorTelefoneCelular" })
    setEmbarcadorTelefoneCelular(embarcadorTelefoneCelular: string) {
        return embarcadorTelefoneCelular
    }

    //Tomador

    @Mutation
    public _setTomadorCnpj(tomadorCnpj: string) {
        this.freteState.Tomador.cnpj = tomadorCnpj
    }

    @Action({ commit: "_setTomadorCnpj" })
    public setTomadorCnpj(tomadorCnpj: string) {
        return tomadorCnpj
    }

    @Mutation
    public _setTomadorNome(tomadorNome: string) {
        this.freteState.Tomador.nome = tomadorNome
    }

    @Action({ commit: "_setTomadorNome" })
    public setTomadorNome(tomadorNome: string) {
        return tomadorNome
    }

    @Mutation
    public _setTomadorEmail(tomadorEmail: string) {
        this.freteState.Tomador.email = tomadorEmail
    }

    @Action({ commit: "_setTomadorEmail" })
    public setTomadorEmail(tomadorEmail: string) {
        return tomadorEmail
    }

    //ContatoWhatsApp

    @Mutation
    _setContatoWhatsApp(contatoWhatsApp: FreteContatoWhatsAppDTO) {
        this.freteState.ContatoWhatsApp.push(contatoWhatsApp)
    }

    @Action({ commit: "_setContatoWhatsApp" })
    setContatoWhatsApp(contatoWhatsApp: FreteContatoWhatsAppDTO) {
        return contatoWhatsApp
    }

    //Carroceria

    @Mutation
    _setCarrocerias(carroceria: FreteCarroceriaDTO[]) {
        this.freteState.Carrocerias = carroceria
    }

    @Action({ commit: "_setCarrocerias" })
    setCarrocerias(carroceria: FreteCarroceriaDTO) {
        return carroceria
    }

    //Produtos

    @Mutation
    _setProduto(produto: FreteProdutoDTO) {
        this.freteState.Produtos.push(produto)
    }

    @Action({ commit: "_setProduto" })
    setProduto(produto: FreteProdutoDTO) {
        return produto
    }

    @Mutation
    _deleteProduto(index: number) {
        this.freteState.Produtos.splice(index, 1)
    }

    @Action({ commit: "_deleteProduto" })
    deleteProduto(index: number) {
        return index
    }



}

export default FreteModule



