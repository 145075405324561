import { RouteConfig } from "vue-router"
import UrlLauncher from "@/components/urlLauncher/UrlLauncher.vue"

export default class UrlLauncherRoute {
    static route(): RouteConfig {
        return {
            path: "/url-launcher",
            name: "UrlLauncher",
            component: UrlLauncher
        }
    }
}