




import ConfigDTO from "@/domains/application/ConfigDTO";
import JWTDTO from "@/domains/application/JWTDTO";
import LocalStorageProvider, {
  LocalStorageEnum,
} from "@/providers/LocalStorageProviders";
import jwtDecode from "jwt-decode";
import { Vue, Component } from "vue-property-decorator";
import EncoderProvider from "@/providers/EncoderProvider";
import { PlanosEnum } from "@/domains/planos/PlanosEnum";

@Component
export default class UrlLauncher extends Vue {
  mounted() {
    try {
      const token = this.$route.query.k as string;
      const route = this.$route.query.route as string;
      const plan = this.$route.query.plan as string;
      const modulos = this.$route.query.modulos as string;
      const tab = this.$route.query.tab as string;

      if (![null, undefined, "", 0, NaN, "null"].includes(token)) {
        const tokenInfo = jwtDecode(token) as JWTDTO;
        const idUsuario = tokenInfo.id_usuario;
        const cpf = tokenInfo.username;

        if (
          ![null, undefined, "", 0, NaN, "null"].includes(idUsuario) &&
          ![null, undefined, "", 0, NaN, "null"].includes(cpf)
        ) {
          LocalStorageProvider.store<ConfigDTO>(
            { jwt: token, id_usuario: idUsuario, cpf: cpf },
            LocalStorageEnum.CONFIG_KEY
          );
          this.$store.dispatch(
            "contratacaostate/setid_usuario",
            Number(idUsuario)
          );
        }
      }

      let modules = "";
      if (![null, undefined, "", 0, NaN, "null", "undefined"].includes(modulos))
        modules = JSON.parse(EncoderProvider.decode(modulos));

      const plano = plan || "";
      const ramoSelecionado =
        tab === "transportadora" ? "transportadora" : "industria_fabrica";

      switch (route) {
        case "planos":
          this.$router.push("/planos");
          break;
        case "contratacao-planos":
          if (
            plan === PlanosEnum.TRIAL ||
            ramoSelecionado === "transportadora"
          ) {
            this.$store.dispatch(
              "contratacaostate/setplano_selecionado",
              plano
            );
            this.$store.dispatch(
              "contratacaostate/setmodulos_selecionados",
              modules
            );
            this.$store.dispatch(
              "contratacaostate/setRamoSelecionado",
              ramoSelecionado
            );
            setTimeout(() => {
              this.$router.push("/contratacao");
            }, 500);
          } else {
            // this.$store.dispatch("contratacaostate/setmodulos_selecionados", modules)
            // this.$store.dispatch("contratacaostate/setRamoSelecionado", ramoSelecionado)
            this.$router.push("/planos");
          }

          break;
        default:
          console.error("Rota invalida");
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }
}
