import Vue from 'vue'
import Vuex from 'vuex'
import { ApplicationState } from './modules/ApplicationState'
import ContratacaoModule from './modules/ContratacaoState'
import CotacaoModule from './modules/CotacaoState'
import FreteModule from './modules/FreteState'

Vue.use(Vuex)

export interface RootState {
    value: string
}


const store = new Vuex.Store({
    modules: {
        appstate: ApplicationState,
        contratacaostate: ContratacaoModule,
        fretestate: FreteModule,
        cotacaostate: CotacaoModule
    }
})

export default store