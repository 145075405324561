import { RouteConfig } from "vue-router";
// import NotFound from '@/components/application/NotFoundPage.vue'
export default class NotFoundRoute {
  static route(): RouteConfig {
    return {
      path: "*",
      name: "NotFound",
      redirect: { path: "/planos" },
      // component: NotFound
    };
  }
}
