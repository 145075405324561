import ContratacaoDTO from "@/domains/contratacao/ContratacaoDTO";
import store from "@/store";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { EntidadePessoaJuridicaSintegraDTO } from "@/domains/entidades/EntidadePessoaJuridicaDTO";
import {
  PlanosContratanteModulosDTO,
  PlanoSelecionadoDTO,
} from "@/domains/planos/PlanosDTO";
import UsuarioInformacoesPublicasDTO from "@/domains/usuario/UsuarioInformacoesPublicasDTO";

@Module({
  namespaced: true,
  store,
})
class ContratacaoModule extends VuexModule {
  private contratacaoState: ContratacaoDTO = {
    id_usuario: 0,
    tipo: "",
    responsavel_nome: "",
    responsavel_cadastro_nova_conta_nome: "",
    responsavel_cadastro_nova_conta_sobrenome: "",
    responsavel_cpf: "",
    responsavel_data_nascimento: "",
    email: "",
    email_financeiro: "",
    telefone: "",
    razao_social: "",
    nome_fantasia: "",
    cnpj: "",
    inscricao_estadual: "",
    rua: "",
    numero: 0,
    cep: "",
    bairro: "",
    id_estado: 0,
    id_municipio: 0,
    regime_tributario: "",
    faturamento_anual: "",
    funcionarios: "",
    produtor_rural: "",
    cooperativa: "",
    realiza_transporte_container: "",
    forma_pagamento: "Boleto",
    tipo_cargas: [],
    segmentos: [],
    setor_economico: "",
    outros_tipos_cargas: "",
    outro_setor_economico: "",
    outro_segmento: "",
    termo_uso_aceito: false,
    menu_selecionado: "CONTRATACAO_DADOS_RESPONSAVEL",
    tipo_cadastro: "CONTRATACAO",
    step_contratacao: 1,
    uf_pessoa_juridica: "",
    municipio_pessoa_juridica: "",
    data_abertura_empresa: "",

    transportadora_seguradora: "Não",
    transportadora_cobertura_seguro_por_carga: "",
    transportadora_vigencia_seguro: "",
    transportadora_possui_seguro_ambiental: "Não",
    transportadora_possui_gerenciadora_de_risco: "Não",
    transportadora_frota_propria_numero_caminhoes_cavalos: 0,
    transportadora_frota_propria_numero_motoristas: 0,
    transportadora_frota_agregada_numero_caminhoes_cavalos: 0,
    transportadora_frota_agregada_numero_motoristas: 0,
    transportadora_perfil_carrocerias: [],
    transportadora_gerenciador_de_risco: "",

    plano_selecionado: "",

    plano_selecionado_completo: {
      adicional_por_embarque: "",
      adicional_por_embarque_valor: 0,
      adicional_por_filial: "",
      adicional_por_filial_valor: 0,
      agendamento_adicional_por_pedido: "",
      agendamento_adicional_por_pedido_valor: 0,
      agendamento_quantidade_mensal_pedidos: 0,
      agendamento_valor_modulo: 0,
      ativo: "",
      condicao_pagamento: "",
      controle_filiais: "",
      controle_frota: "",
      cotacao_quantidade_mensal: 0,
      cotacao_tarifacao: "",
      cotacao_tarifacao_valor: 0,
      cotacao_valor_adicional_pos_limite: 0,
      cotacao_valor_modulo: 0,
      customer_experience: 0,
      customizacao: "",
      data_atualizacao: "",
      data_cadastro: "",
      dias_trial: 0,
      embarque_valor_adicional_pos_limite: 0,
      envio_whatsapp: "",
      fidelidade: "",
      fidelidade_meses: 0,
      frota_controle_veiculo: "",
      frota_quantidade_veiculo: 0,
      id_plano: 5,
      incentivo: "",
      integracao: "",
      limite_uso_credito_pos_pago: 0,
      nome: "",
      plano_gratuito: "",
      pos_embarque_adicional_por_embarque_cobranca: "",
      pos_embarque_valor_modulo: 0,
      quantidade_embarques_mes: 0,
      quantidade_filiais: 0,
      quantidade_usuarios: 0,
      real_time_visibility: "",
      suporte: "",
      tamanho_frota: 0,
      tipo: "",
      transportador_representante: "",
      treinamento: "",
      trial: "",
      usuario_valor_adicional_pos_limite: 0,
      validade_meses: 0,
      valor_mensagem_whatsapp: 0,
    },

    situacao_cpf_usuario: "",
    finalizado_cadastro_empresa: false,
    finalizado_cadastro_usuario: false,

    modulos_selecionados: {
      gestao: false,
      cotacao: false,
      contratacao_direta: false,
      agendamento_embarques: false,
    },

    usuario_logado: false,
    cadastro_frete_ativo: false,
    cadastro_cotacao_ativo: false,

    cadastrou_informacoes_adicionais: false,

    abasCadastro: ["DADOS_DA_EMPRESA"],

    ramoSelecionado: "",
  };

  get contratacao() {
    return this.contratacaoState;
  }

  @Mutation
  _setResponsavelNome(responsavelNome: string) {
    this.contratacaoState.responsavel_nome = responsavelNome;
  }

  @Action({ commit: "_setResponsavelNome" })
  setResponsavelNome(responsavelNome: string) {
    return responsavelNome;
  }

  @Mutation
  _settipo(tipo: string) {
    this.contratacaoState.tipo = tipo;
  }

  @Action({ commit: "_settipo" })
  settipo(tipo: string) {
    return tipo;
  }

  @Mutation
  _setResponsavelCpf(responsavel_cpf: string) {
    this.contratacaoState.responsavel_cpf = responsavel_cpf;
  }

  @Action({ commit: "_setResponsavelCpf" })
  setResponsavelCpf(cpf: string) {
    return cpf;
  }

  @Mutation
  _setResponsavelDataNascimento(responsavel_data_nascimento: string) {
    this.contratacaoState.responsavel_data_nascimento =
      responsavel_data_nascimento;
  }

  @Action({ commit: "_setResponsavelDataNascimento" })
  setResponsavelDataNascimento(responsavel_data_nascimento: string) {
    return responsavel_data_nascimento;
  }

  @Mutation
  _setEmail(email: string) {
    this.contratacaoState.email = email;
  }

  @Action({ commit: "_setEmail" })
  setEmail(email: string) {
    return email;
  }

  @Mutation
  _setEmailFinanceiro(email_financeiro: string) {
    this.contratacaoState.email_financeiro = email_financeiro;
  }

  @Action({ commit: "_setEmailFinanceiro" })
  setEmailFinanceiro(email_financeiro: string) {
    return email_financeiro;
  }

  @Mutation
  _setTelefone(telefone: string) {
    this.contratacaoState.telefone = telefone;
  }

  @Action({ commit: "_setTelefone" })
  setTelefone(telefone: string) {
    return telefone;
  }

  @Mutation
  _setRazaoSocial(razao_social: string) {
    this.contratacaoState.razao_social = razao_social;
  }

  @Action({ commit: "_setRazaoSocial" })
  setRazaoSocial(razao_social: string) {
    return razao_social;
  }

  @Mutation
  _setNomeFantasia(nome_fantasia: string) {
    console.log("[setNomeFantasia]", nome_fantasia);
    this.contratacaoState.nome_fantasia = nome_fantasia;
  }

  @Action({ commit: "_setNomeFantasia" })
  setNomeFantasia(nome_fantasia: string) {
    return nome_fantasia;
  }

  @Mutation
  _setCnpj(cnpj: string) {
    this.contratacaoState.cnpj = cnpj;
  }

  @Action({ commit: "_setCnpj" })
  setCnpj(cnpj: string) {
    return cnpj;
  }

  @Mutation
  _setInscricaoEstadual(inscricao_estadual: string) {
    this.contratacaoState.inscricao_estadual = inscricao_estadual;
  }

  @Action({ commit: "_setInscricaoEstadual" })
  setInscricaoEstadual(inscricao_estadual: string) {
    return inscricao_estadual;
  }

  @Mutation
  _setRua(rua: string) {
    this.contratacaoState.rua = rua;
  }

  @Action({ commit: "_setRua" })
  setRua(rua: string) {
    return rua;
  }

  @Mutation
  _setNumero(numero: number) {
    this.contratacaoState.numero = numero;
  }

  @Action({ commit: "_setNumero" })
  setNumero(numero: number) {
    return numero;
  }

  @Mutation
  _setCep(cep: string) {
    this.contratacaoState.cep = cep;
  }

  @Action({ commit: "_setCep" })
  setCep(cep: string) {
    return cep;
  }

  @Mutation
  _setBairro(bairro: string) {
    this.contratacaoState.bairro = bairro;
  }

  @Action({ commit: "_setBairro" })
  setBairro(bairro: string) {
    return bairro;
  }

  @Mutation
  _setIdEstado(id_estado: number) {
    this.contratacaoState.id_estado = id_estado;
  }

  @Action({ commit: "_setIdEstado" })
  setIdEstado(id_estado: number) {
    return id_estado;
  }

  @Mutation
  _setIdMunicipio(id_municipio: number) {
    this.contratacaoState.id_municipio = id_municipio;
  }

  @Action({ commit: "_setIdMunicipio" })
  setIdMunicipio(id_municipio: number) {
    return id_municipio;
  }

  @Mutation
  _setRegimeTributario(regime_tributario: string) {
    this.contratacaoState.regime_tributario = regime_tributario;
  }

  @Action({ commit: "_setRegimeTributario" })
  setRegimeTributario(regime_tributario: string) {
    return regime_tributario;
  }

  @Mutation
  _setDataAberturaEmpresa(data_abertura_empresa: string) {
    this.contratacaoState.data_abertura_empresa = data_abertura_empresa;
  }

  @Action({ commit: "_setDataAberturaEmpresa" })
  setDataAberturaEmpresa(data_abertura_empresa: string) {
    return data_abertura_empresa;
  }

  @Mutation
  _setFaturamentoAnual(faturamento_anual: string) {
    this.contratacaoState.faturamento_anual = faturamento_anual;
  }

  @Action({ commit: "_setFaturamentoAnual" })
  setFaturamentoAnual(faturamento_anual: string) {
    return faturamento_anual;
  }

  @Mutation
  _setFuncionarios(funcionarios: string) {
    this.contratacaoState.funcionarios = funcionarios;
  }

  @Action({ commit: "_setFuncionarios" })
  setFuncionarios(funcionarios: string) {
    return funcionarios;
  }

  @Mutation
  _setProdutorRural(produtor_rural: string) {
    this.contratacaoState.produtor_rural = produtor_rural;
  }

  @Action({ commit: "_setProdutorRural" })
  setProdutorRural(produtor_rural: string) {
    return produtor_rural;
  }

  @Mutation
  _setCooperativa(cooperativa: string) {
    this.contratacaoState.cooperativa = cooperativa;
  }

  @Action({ commit: "_setCooperativa" })
  setCooperativa(cooperativa: string) {
    return cooperativa;
  }

  @Mutation
  _setRealizaTransportesEmContainer(realiza_transporte_container: string) {
    this.contratacaoState.realiza_transporte_container =
      realiza_transporte_container;
  }

  @Action({ commit: "_setRealizaTransportesEmContainer" })
  setRealizaTransportesEmContainer(realiza_transporte_container: string) {
    return realiza_transporte_container;
  }

  @Mutation
  _setFormaDePagamento(forma_pagamento: string) {
    this.contratacaoState.forma_pagamento = forma_pagamento;
  }

  @Action({ commit: "_setFormaDePagamento" })
  setFormaDePagamento(forma_pagamento: string) {
    return forma_pagamento;
  }

  @Mutation
  _setTipoCargasTransportadas(tipo_cargas: string[]) {
    this.contratacaoState.tipo_cargas = tipo_cargas;
  }

  @Action({ commit: "_setTipoCargasTransportadas" })
  setTipoCargasTransportadas(tipo_cargas: string) {
    return tipo_cargas;
  }

  @Mutation
  _setSegmentos(segmentos: string[]) {
    this.contratacaoState.segmentos = segmentos;
  }

  @Action({ commit: "_setSegmentos" })
  setSegmentos(segmentos: string) {
    return segmentos;
  }

  @Mutation
  _setSetorEconomico(setor_economico: string) {
    this.contratacaoState.setor_economico = setor_economico;
  }

  @Action({ commit: "_setSetorEconomico" })
  setSetorEconomico(setor_economico: string) {
    return setor_economico;
  }

  @Mutation
  _setOutrosTiposCargas(outros_tipos_cargas: string) {
    this.contratacaoState.outros_tipos_cargas = outros_tipos_cargas;
  }

  @Action({ commit: "_setOutrosTiposCargas" })
  setOutrosTiposCargas(outros_tipos_cargas: string) {
    return outros_tipos_cargas;
  }

  @Mutation
  _setOutroSetorEconomico(outro_setor_economico: string) {
    this.contratacaoState.outro_setor_economico = outro_setor_economico;
  }

  @Action({ commit: "_setOutroSetorEconomico" })
  setOutroSetorEconomico(outro_setor_economico: string) {
    return outro_setor_economico;
  }

  @Mutation
  _setOutroSegmento(outro_segmento: string) {
    this.contratacaoState.outro_segmento = outro_segmento;
  }

  @Action({ commit: "_setOutroSegmento" })
  setOutroSegmento(outro_segmento: string) {
    return outro_segmento;
  }

  @Mutation
  _setTermoUsoAceito(termo_uso_aceito: boolean) {
    this.contratacaoState.termo_uso_aceito = termo_uso_aceito;
  }

  @Action({ commit: "_setTermoUsoAceito" })
  setTermoUsoAceito(termo_uso_aceito: boolean) {
    return termo_uso_aceito;
  }

  @Mutation
  _setMenuSelecionado(menu_selecionado: string) {
    this.contratacaoState.menu_selecionado = menu_selecionado;
  }

  @Action({ commit: "_setMenuSelecionado" })
  setMenuSelecionado(menu_selecionado: string) {
    return menu_selecionado;
  }

  @Mutation
  _setTipoCadastro(tipo_cadastro: string) {
    this.contratacaoState.tipo_cadastro = tipo_cadastro;
  }

  @Action({ commit: "_setTipoCadastro" })
  setTipoCadastro(tipo_cadastro: string) {
    return tipo_cadastro;
  }

  @Mutation
  _setStepContratacao(step_contratacao: number) {
    this.contratacaoState.step_contratacao = step_contratacao;
  }

  @Action({ commit: "_setStepContratacao" })
  setStepContratacao(step_contratacao: number) {
    return step_contratacao;
  }

  @Mutation
  _setInformacoesEmpresaPeloCNPJ(
    pessoaJuridica: EntidadePessoaJuridicaSintegraDTO
  ) {
    this.contratacaoState.nome_fantasia =
      !pessoaJuridica.receitaFederalCnpj ||
      !pessoaJuridica.receitaFederalCnpj.nomeFantasia
        ? ""
        : pessoaJuridica.receitaFederalCnpj.nomeFantasia === "********"
        ? pessoaJuridica.receitaFederalCnpj.nomeFantasia.replace("********", "")
        : pessoaJuridica.receitaFederalCnpj.nomeFantasia;
    this.contratacaoState.rua =
      !pessoaJuridica.receitaFederalCnpj ||
      !pessoaJuridica.receitaFederalCnpj.logradouro
        ? ""
        : pessoaJuridica.receitaFederalCnpj.logradouro === "********"
        ? pessoaJuridica.receitaFederalCnpj.logradouro.replace("********", "")
        : pessoaJuridica.receitaFederalCnpj.logradouro;
    this.contratacaoState.numero =
      !pessoaJuridica.receitaFederalCnpj ||
      !pessoaJuridica.receitaFederalCnpj.numero
        ? 0
        : pessoaJuridica.receitaFederalCnpj.numero === "********"
        ? 0
        : Number(pessoaJuridica.receitaFederalCnpj.numero);
    this.contratacaoState.cep = !pessoaJuridica.receitaFederalCnpj.cep
      ? ""
      : pessoaJuridica.receitaFederalCnpj.cep.toString();
    this.contratacaoState.bairro =
      !pessoaJuridica.receitaFederalCnpj ||
      !pessoaJuridica.receitaFederalCnpj.bairroDistrito
        ? ""
        : pessoaJuridica.receitaFederalCnpj.bairroDistrito === "********"
        ? pessoaJuridica.receitaFederalCnpj.bairroDistrito.replace(
            "********",
            ""
          )
        : pessoaJuridica.receitaFederalCnpj.bairroDistrito;
    this.contratacaoState.uf_pessoa_juridica =
      !pessoaJuridica.receitaFederalCnpj ||
      !pessoaJuridica.receitaFederalCnpj.uf
        ? ""
        : pessoaJuridica.receitaFederalCnpj.uf === "********"
        ? pessoaJuridica.receitaFederalCnpj.uf.replace("********", "")
        : pessoaJuridica.receitaFederalCnpj.uf;
    this.contratacaoState.municipio_pessoa_juridica =
      !pessoaJuridica.receitaFederalCnpj ||
      !pessoaJuridica.receitaFederalCnpj.municipio
        ? ""
        : pessoaJuridica.receitaFederalCnpj.municipio === "********"
        ? pessoaJuridica.receitaFederalCnpj.municipio.replace("********", "")
        : pessoaJuridica.receitaFederalCnpj.municipio;
    this.contratacaoState.razao_social =
      !pessoaJuridica.sintegra || !pessoaJuridica.sintegra.razaoSocial
        ? ""
        : pessoaJuridica.sintegra.razaoSocial === "********"
        ? pessoaJuridica.sintegra.razaoSocial.replace("********", "")
        : pessoaJuridica.sintegra.razaoSocial;
    this.contratacaoState.inscricao_estadual =
      !pessoaJuridica.sintegra || !pessoaJuridica.sintegra.inscricaoEstadual
        ? ""
        : pessoaJuridica.sintegra.inscricaoEstadual === "********"
        ? pessoaJuridica.sintegra.inscricaoEstadual.replace("********", "")
        : pessoaJuridica.sintegra.inscricaoEstadual;
  }

  @Action({ commit: "_setInformacoesEmpresaPeloCNPJ" })
  setInformacoesEmpresaPeloCNPJ(
    pessoaJuridica: EntidadePessoaJuridicaSintegraDTO
  ) {
    return pessoaJuridica;
  }

  //Transportadora
  @Mutation
  _setTransportadora_seguradora(transportadora_seguradora: string) {
    this.contratacaoState.transportadora_seguradora = transportadora_seguradora;
  }

  @Action({ commit: "_setTransportadora_seguradora" })
  setTransportadora_seguradora(transportadora_seguradora: string) {
    return transportadora_seguradora;
  }

  @Mutation
  _setTransportadora_cobertura_seguro_por_carga(
    transportadora_cobertura_seguro_por_carga: string
  ) {
    this.contratacaoState.transportadora_cobertura_seguro_por_carga =
      transportadora_cobertura_seguro_por_carga;
  }

  @Action({ commit: "_setTransportadora_cobertura_seguro_por_carga" })
  setTransportadora_cobertura_seguro_por_carga(
    transportadora_cobertura_seguro_por_carga: string
  ) {
    return transportadora_cobertura_seguro_por_carga;
  }

  @Mutation
  _setTransportadora_vigencia_seguro(transportadora_vigencia_seguro: string) {
    this.contratacaoState.transportadora_vigencia_seguro =
      transportadora_vigencia_seguro;
  }

  @Action({ commit: "_setTransportadora_vigencia_seguro" })
  setTransportadora_vigencia_seguro(transportadora_vigencia_seguro: string) {
    return transportadora_vigencia_seguro;
  }

  @Mutation
  _setTransportadora_possui_seguro_ambiental(
    transportadora_possui_seguro_ambiental: string
  ) {
    this.contratacaoState.transportadora_possui_seguro_ambiental =
      transportadora_possui_seguro_ambiental;
  }

  @Action({ commit: "_setTransportadora_possui_seguro_ambiental" })
  setTransportadora_possui_seguro_ambiental(
    transportadora_possui_seguro_ambiental: string
  ) {
    return transportadora_possui_seguro_ambiental;
  }

  @Mutation
  _setTransportadora_possui_gerenciadora_de_risco(
    transportadora_possui_gerenciadora_de_risco: string
  ) {
    this.contratacaoState.transportadora_possui_gerenciadora_de_risco =
      transportadora_possui_gerenciadora_de_risco;
  }

  @Action({ commit: "_setTransportadora_possui_gerenciadora_de_risco" })
  setTransportadora_possui_gerenciadora_de_risco(
    transportadora_possui_gerenciadora_de_risco: string
  ) {
    return transportadora_possui_gerenciadora_de_risco;
  }

  @Mutation
  _setTransportadora_frota_propria_numero_caminhoes_cavalos(
    transportadora_frota_propria_numero_caminhoes_cavalos: number
  ) {
    this.contratacaoState.transportadora_frota_propria_numero_caminhoes_cavalos =
      transportadora_frota_propria_numero_caminhoes_cavalos;
  }

  @Action({
    commit: "_setTransportadora_frota_propria_numero_caminhoes_cavalos",
  })
  setTransportadora_frota_propria_numero_caminhoes_cavalos(
    transportadora_frota_propria_numero_caminhoes_cavalos: number
  ) {
    return transportadora_frota_propria_numero_caminhoes_cavalos;
  }

  @Mutation
  _setTransportadora_frota_propria_numero_motoristas(
    transportadora_frota_propria_numero_motoristas: number
  ) {
    this.contratacaoState.transportadora_frota_propria_numero_motoristas =
      transportadora_frota_propria_numero_motoristas;
  }

  @Action({ commit: "_setTransportadora_frota_propria_numero_motoristas" })
  setTransportadora_frota_propria_numero_motoristas(
    transportadora_frota_propria_numero_motoristas: number
  ) {
    return transportadora_frota_propria_numero_motoristas;
  }

  @Mutation
  _setTransportadora_frota_agregada_numero_caminhoes_cavalos(
    transportadora_frota_agregada_numero_caminhoes_cavalos: number
  ) {
    this.contratacaoState.transportadora_frota_agregada_numero_caminhoes_cavalos =
      transportadora_frota_agregada_numero_caminhoes_cavalos;
  }

  @Action({
    commit: "_setTransportadora_frota_agregada_numero_caminhoes_cavalos",
  })
  setTransportadora_frota_agregada_numero_caminhoes_cavalos(
    transportadora_frota_agregada_numero_caminhoes_cavalos: number
  ) {
    return transportadora_frota_agregada_numero_caminhoes_cavalos;
  }

  @Mutation
  _setTransportadora_frota_agregada_numero_motoristas(
    transportadora_frota_agregada_numero_motoristas: number
  ) {
    this.contratacaoState.transportadora_frota_agregada_numero_motoristas =
      transportadora_frota_agregada_numero_motoristas;
  }

  @Action({ commit: "_setTransportadora_frota_agregada_numero_motoristas" })
  setTransportadora_frota_agregada_numero_motoristas(
    transportadora_frota_agregada_numero_motoristas: number
  ): number {
    return transportadora_frota_agregada_numero_motoristas;
  }

  @Mutation
  _settransportadora_perfil_carrocerias(
    transportadora_perfil_carrocerias: string[]
  ) {
    this.contratacaoState.transportadora_perfil_carrocerias =
      transportadora_perfil_carrocerias;
  }

  @Action({ commit: "_settransportadora_perfil_carrocerias" })
  settransportadora_perfil_carrocerias(
    transportadora_perfil_carrocerias: string[]
  ): string[] {
    return transportadora_perfil_carrocerias;
  }

  @Mutation
  _settransportadora_gerenciador_de_risco(
    transportadora_gerenciador_de_risco: string
  ) {
    this.contratacaoState.transportadora_gerenciador_de_risco =
      transportadora_gerenciador_de_risco;
  }

  @Action({ commit: "_settransportadora_gerenciador_de_risco" })
  settransportadora_gerenciador_de_risco(
    transportadora_gerenciador_de_risco: string
  ): string {
    return transportadora_gerenciador_de_risco;
  }

  //Plano
  @Mutation
  _setplano_selecionado(plano_selecionado: string) {
    this.contratacaoState.plano_selecionado = plano_selecionado;
  }

  @Action({ commit: "_setplano_selecionado" })
  setplano_selecionado(plano_selecionado: string): string {
    return plano_selecionado;
  }

  @Mutation
  _setmodulos_selecionados(modulos_selecionados: PlanosContratanteModulosDTO) {
    this.contratacaoState.modulos_selecionados = modulos_selecionados;
  }

  @Action({ commit: "_setmodulos_selecionados" })
  setmodulos_selecionados(modulos_selecionados: PlanosContratanteModulosDTO) {
    return modulos_selecionados;
  }

  @Mutation
  _setplano_selecionado_completo(
    plano_selecionado_completo: PlanoSelecionadoDTO
  ) {
    this.contratacaoState.tipo = plano_selecionado_completo.tipo;
    this.contratacaoState.plano_selecionado_completo =
      plano_selecionado_completo;
  }

  @Action({ commit: "_setplano_selecionado_completo" })
  setplano_selecionado_completo(
    plano_selecionado_completo: PlanoSelecionadoDTO
  ) {
    return plano_selecionado_completo;
  }

  @Mutation
  _setusuario_logado(usuario_logado: boolean) {
    this.contratacaoState.usuario_logado = usuario_logado;
  }

  @Action({ commit: "_setusuario_logado" })
  setusuario_logado(usuario_logado: boolean): boolean {
    return usuario_logado;
  }

  @Mutation
  _setid_usuario(id_usuario: number) {
    this.contratacaoState.id_usuario = id_usuario;
  }

  @Action({ commit: "_setid_usuario" })
  setid_usuario(id_usuario: number) {
    return id_usuario;
  }

  @Mutation
  _setcadastro_frete_ativo(cadastro_frete_ativo: boolean) {
    this.contratacaoState.cadastro_frete_ativo = cadastro_frete_ativo;
  }

  @Action({ commit: "_setcadastro_frete_ativo" })
  setcadastro_frete_ativo(cadastro_frete_ativo: boolean) {
    return cadastro_frete_ativo;
  }

  @Mutation
  _setcadastro_cotacao_ativo(cadastro_cotacao_ativo: boolean) {
    this.contratacaoState.cadastro_cotacao_ativo = cadastro_cotacao_ativo;
  }

  @Action({ commit: "_setcadastro_cotacao_ativo" })
  setcadastro_cotacao_ativo(cadastro_cotacao_ativo: boolean) {
    return cadastro_cotacao_ativo;
  }

  @Mutation
  _setCadastrou_informacoes_adicionais(
    cadastrou_informacoes_adicionais: boolean
  ) {
    this.contratacaoState.cadastrou_informacoes_adicionais =
      cadastrou_informacoes_adicionais;
  }

  @Action({ commit: "_setCadastrou_informacoes_adicionais" })
  setCadastrou_informacoes_adicionais(
    cadastrou_informacoes_adicionais: boolean
  ) {
    return cadastrou_informacoes_adicionais;
  }

  @Mutation
  _setfinalizado_cadastro_empresa(finalizado_cadastro_empresa: boolean) {
    this.contratacaoState.finalizado_cadastro_empresa =
      finalizado_cadastro_empresa;
  }

  @Action({ commit: "_setfinalizado_cadastro_empresa" })
  setfinalizado_cadastro_empresa(finalizado_cadastro_empresa: boolean) {
    return finalizado_cadastro_empresa;
  }

  @Mutation
  _setfinalizado_cadastro_usuario(finalizado_cadastro_usuario: boolean) {
    this.contratacaoState.finalizado_cadastro_usuario =
      finalizado_cadastro_usuario;
  }

  @Action({ commit: "_setfinalizado_cadastro_usuario" })
  setfinalizado_cadastro_usuario(finalizado_cadastro_usuario: boolean) {
    return finalizado_cadastro_usuario;
  }

  @Mutation
  _setsituacao_cpf_usuario(situacao_cpf_usuario: string) {
    this.contratacaoState.situacao_cpf_usuario = situacao_cpf_usuario;
  }

  @Action({ commit: "_setsituacao_cpf_usuario" })
  setsituacao_cpf_usuario(situacao_cpf_usuario: string) {
    return situacao_cpf_usuario;
  }

  @Mutation
  _setInformacoesPublicasUsuario(
    informacoesUsuario: UsuarioInformacoesPublicasDTO
  ) {
    this.contratacaoState.responsavel_nome = informacoesUsuario.nome;
    this.contratacaoState.email = informacoesUsuario.email;
    this.contratacaoState.telefone = informacoesUsuario.telefone;
  }

  @Action({ commit: "_setInformacoesPublicasUsuario" })
  setInformacoesPublicasUsuario(
    informacoesUsuario: UsuarioInformacoesPublicasDTO
  ) {
    return informacoesUsuario;
  }

  @Mutation
  _setNomeSobrenomeCadastroNovaConta(nomeCompleto: string) {
    const nome = nomeCompleto.split(" ")[0];
    const sobrenome = nomeCompleto.split(" ").splice(1).join(" ");
    this.contratacaoState.responsavel_cadastro_nova_conta_nome = nome;
    this.contratacaoState.responsavel_cadastro_nova_conta_sobrenome = sobrenome;
  }

  @Action({ commit: "_setNomeSobrenomeCadastroNovaConta" })
  setNomeSobrenomeCadastroNovaConta(nomeCompleto: string) {
    return nomeCompleto;
  }

  @Mutation
  _setNomeCadastroNovaConta(nome: string) {
    this.contratacaoState.responsavel_cadastro_nova_conta_nome = nome;
  }

  @Action({ commit: "_setNomeCadastroNovaConta" })
  setNomeCadastroNovaConta(nome: string) {
    return nome;
  }

  @Mutation
  _setSobrenomeCadastroNovaConta(sobreNome: string) {
    this.contratacaoState.responsavel_cadastro_nova_conta_sobrenome = sobreNome;
  }

  @Action({ commit: "_setSobrenomeCadastroNovaConta" })
  setSobrenomeCadastroNovaConta(sobreNome: string) {
    return sobreNome;
  }

  @Mutation
  _setRamoSelecionado(ramoSelecionado: string) {
    this.contratacaoState.ramoSelecionado = ramoSelecionado;
  }

  @Action({ commit: "_setRamoSelecionado" })
  setRamoSelecionado(ramoSelecionado: string) {
    return ramoSelecionado;
  }

  @Mutation
  _cleanInformacoesEmpresa() {
    this.contratacaoState.nome_fantasia = "";
    this.contratacaoState.rua = "";
    this.contratacaoState.numero = 0;
    this.contratacaoState.cep = "";
    this.contratacaoState.bairro = "";
    this.contratacaoState.uf_pessoa_juridica = "";
    this.contratacaoState.municipio_pessoa_juridica = "";
    this.contratacaoState.razao_social = "";
    this.contratacaoState.inscricao_estadual = "";
    this.contratacaoState.id_municipio = 0;
  }

  @Action
  cleanInformacoesEmpresa() {
    this.context.commit("_cleanInformacoesEmpresa");
  }

  @Mutation
  _cleanInformacoesResponsavel() {
    this.contratacaoState.responsavel_nome = "";
    this.contratacaoState.responsavel_data_nascimento = "";
    this.contratacaoState.email = "";
    this.contratacaoState.telefone = "";
    this.contratacaoState.responsavel_cpf = "";
  }

  @Action
  cleanInformacoesResponsavel() {
    this.context.commit("_cleanInformacoesResponsavel");
  }

  @Mutation
  _removerAba(aba: string) {
    const index = this.contratacaoState.abasCadastro.findIndex(
      (el) => el === aba
    );
    if (index !== -1) this.contratacaoState.abasCadastro.splice(index, 1);
  }

  @Action({ commit: "_removerAba" })
  removerAba(aba: string) {
    return aba;
  }

  @Mutation
  _removerAbaPeloIndex(index: number) {
    this.contratacaoState.abasCadastro.splice(index, 1);
  }

  @Action({ commit: "_removerAbaPeloIndex" })
  rremoverAbaPeloIndex(index: number) {
    return index;
  }

  @Mutation
  _adicionarNovaAba(aba: string) {
    if (this.contratacaoState.abasCadastro.some((el) => el === aba)) return;
    this.contratacaoState.abasCadastro.push(aba);
  }

  @Action({ commit: "_adicionarNovaAba" })
  adicionarNovaAba(aba: string) {
    return aba;
  }

  @Mutation
  _removerAbasAPartirDaAbaInformada(aba: string) {
    const index = this.contratacaoState.abasCadastro.findIndex(
      (el) => el === aba
    );
    if (index !== -1)
      this.contratacaoState.abasCadastro.splice(index + 1, Number.MAX_VALUE);
  }

  @Action({ commit: "_removerAbasAPartirDaAbaInformada" })
  removerAbasAPartirDaAbaInformada(aba: string) {
    return aba;
  }

  @Mutation
  _limparListaDeAbas() {
    this.contratacaoState.abasCadastro = ["DADOS_DA_EMPRESA"];
  }

  @Action
  limparListaDeAbas() {
    this.context.commit("_limparListaDeAbas");
  }
}

export default ContratacaoModule;
